import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { isEmpty } from 'lodash';
import ReactTooltip from 'react-tooltip';
import moment from 'moment';
import { Button } from 'reactstrap';

import {
  CalendarIconSmall,
  UserIcon,
  EmergencyIcon,
  ExternalWorkOrderIcon,
  GoBackIcon,
} from 'assets/images/svg-icons';

import PausedNotesModal from 'components/open-jobs/components/paused-notes-modal';
import JobDescriptionModal from 'components/shared/modals/job-description-modal';
import CustomerNotesModal from 'components/shared/modals/customer-notes-modal';
import JobStatus from 'components/open-jobs/components/job-status';
import AskQuestionModal from 'components/open-jobs/components/ask-question-modal';
import TooltipWrapper from 'components/shared/TooltipWrapper';

import { generateRandomString, openInANewTab } from 'helpers/utils';

const JobCard = (props) => {
  const [showPausedNotes, setPausedNotes] = useState(false);
  const [showDescription, setDescription] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);
  const [showAskQuestionModal, setShowAskQuestionModal] = useState(false);
  const {
    job,
    type,
    mapIcon,
    onJobNotesModalCloseHandler,
  } = props;
  const [componentIndex] = useState(generateRandomString());
  const [customerNotesModalData, setCustomerNotesModalData] = useState({});

  const onMouseEnterHandle = () => {
    setShowTooltip(true);
  };
  const onMouseLeaveHandle = () => {
    setShowTooltip(false);
  };

  // const switchDirection = () => {
  //   setComponentIndex(generateRandomString());
  // };

  // const renderTimer = (time) => {
  //   if (moment().unix() >= time) {
  //     return (
  //       <JobTimer
  //         initialTime={moment().unix() - time}
  //         startImmediately
  //         isNegative
  //       />
  //     );
  //   }

  //   return (
  //     <JobTimer
  //       initialTime={time - moment().unix()}
  //       startImmediately
  //       direction="backward"
  //       onComplete={switchDirection}
  //     />
  //   );
  // };

  const onWorkOrderClickHandler = (link) => {
    if (link) {
      openInANewTab(link);
    } else {
      setDescription(true);
    }
  };

  const showReachedOutToTenant = (timings) => {
    const formatTimings = {};
    timings?.forEach((obj) => {
      if (obj.reached_on) {
        const date = moment.utc(obj.reached_on).local();
        const formattedDate = date.format('YYYY-MM-DD');
        formatTimings[formattedDate] = [...(formatTimings[formattedDate] || []), date?.format('hh:mm a')];
      }
    });
    return Object.keys(formatTimings)?.map((itemKey) => (
      <div
        key={itemKey}
        className="reach-out-tenant"
      >
        Reached out to tenant on:
        {' '}
        <span>
          {moment(itemKey)?.format('MMM DD, YYYY')}
          {' '}
          (
          {formatTimings[itemKey].join(', ')}
          )
        </span>
      </div>
    ));
  };

  return (
    <React.Fragment key={componentIndex}>
      {
        !isEmpty(job) && (
          <div className="jobs-card">
            <div className="jobs-card-top-section">
              {job?.status_slug && ['in_progress', 'paused', 'pending']?.includes(type)
                ? (
                  <JobStatus
                    value={job?.status_slug}
                    label={job?.status_label}
                    mapIcon={mapIcon}
                    setPausedNotes={setPausedNotes}
                    onMouseEnterHandle={onMouseEnterHandle}
                    onMouseLeaveHandle={onMouseLeaveHandle}
                    showTooltip={showTooltip}
                    quoteReviewUrl={job?.quote_review_url}
                    jobIssues={job?.job_issues}
                    jobId={job?.job_id}
                  />
                ) : ''}
              {['submitted', 'scheduled']?.includes(type) && !isEmpty(job?.availability_date) ? (
                <TooltipWrapper renderTooltip={(isTooltipOpen, setIsTooltipOpen) => (
                  <React.Fragment>
                    <div className={`availability-provided ${type}`}>
                      Availability Provided by Resident
                      <span
                        data-for={`resident-availability-${job?.job_id}`}
                        data-tip
                        onMouseEnter={() => setIsTooltipOpen(true)}
                        onMouseLeave={() => setIsTooltipOpen(false)}
                      >
                        View
                      </span>

                    </div>
                    {isTooltipOpen
                      ? (
                        <ReactTooltip
                          id={`resident-availability-${job?.job_id}`}
                          className="resident-availability-tooltip"
                          arrowColor="#fff"
                        >
                          <React.Fragment>
                            <h4>Resident Availability:</h4>
                            {job?.availability_date
                            && job?.availability_date?.map((availabilityDate) => (
                              <div className="available-slot">
                                <svg width="7" height="6" viewBox="0 0 7 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <rect x="1" y="0.5" width="5" height="5" rx="1" stroke="#283A5D" />
                                </svg>
                                <p key={generateRandomString()}>
                                  {availabilityDate}
                                </p>
                              </div>
                            ))}
                          </React.Fragment>
                        </ReactTooltip>
                      ) : '' }
                  </React.Fragment>
                )}
                />
              ) : ''}
              {type === 'pending' ? (
                <React.Fragment>
                  {showReachedOutToTenant(job?.reached_out_to_tenants)}
                </React.Fragment>
              ) : (
                ''
              )}
              <div className="data-row">
                <div className="left-data">
                  <CalendarIconSmall />
                  <p><strong>{job.date}</strong></p>
                </div>
                <div className="right-data">
                  {job.job_no ? <button type="button" className="job-id" onClick={() => setDescription(true)}>{`#${job?.job_no}`}</button> : ''}
                  {job.has_go_back_option ? (
                    <Link onMouseEnter={onMouseEnterHandle} onMouseLeave={onMouseLeaveHandle} data-for="go-back-job" data-tip="Go-Back Job" to="#">
                      <GoBackIcon />
                      {showTooltip ? (
                        <ReactTooltip
                          id="go-back-job"
                          arrowColor="#283a5d"
                          className="global-tooltip"
                        />
                      ) : ''}
                    </Link>
                  ) : ''}
                  {job.is_emergency ? <Link onMouseEnter={onMouseEnterHandle} onMouseLeave={onMouseLeaveHandle} data-for="emergencyBtn" data-tip="Emergency Priority" to="#"><EmergencyIcon /></Link> : ''}
                  {showTooltip ? (
                    <ReactTooltip
                      arrowColor="#283a5d"
                      id="emergencyBtn"
                      className="global-tooltip"
                    />
                  ) : ''}
                </div>
              </div>
              {job.property_manager ? (
                <div className="data-row">
                  <div className="left-data">
                    <UserIcon />
                    <p>
                      Submitted by
                      {' '}
                      <strong>{job.property_manager}</strong>
                    </p>
                  </div>
                </div>
              ) : ''}
              <div className="data-row">
                <div className="left-data">
                  <ExternalWorkOrderIcon />
                  {
                    job.external_job_source ? (
                      <p>
                        {job.external_job_source.label}
                        {' '}
                        <button type="button" onClick={() => onWorkOrderClickHandler(job?.external_job_source?.link)}>
                          #
                          {job.external_job_source.reference_no}
                        </button>
                      </p>
                    )
                      : <p>No Work Order Associated</p>
                  }
                </div>
              </div>
            </div>
            <div className="jobs-card-middle-section">
              <ul>
                {job.tenant_name ? <li><strong>{job.tenant_name}</strong></li> : ''}
                {job.address ? (
                  <li>
                    {job.address.split('\n').map((addressInfo) => (
                      <React.Fragment key={generateRandomString()}>
                        {addressInfo}
                        <br />
                      </React.Fragment>
                    ))}
                  </li>
                ) : ''}
                {job?.service_category ? <li><strong>{job?.service_category}</strong></li> : ''}
              </ul>
              {job.pro_arrival_window && type !== 'paused' ? (
                <div className="data-row">
                  <div className="head-data">
                    <CalendarIconSmall />
                    <p><strong>Scheduled For: </strong></p>
                  </div>
                  <div className="sub-data">
                    <div className="date-range">{job.pro_arrival_window}</div>
                  </div>
                </div>
              ) : ''}
              {type === 'paused' ? (
                <div className="data-row">
                  {!['submitted-for-customer-approval', 'quote-submitted']?.includes(job?.status_slug)
                    ? (
                      <React.Fragment>
                        <div className="head-data">
                          <CalendarIconSmall />
                          <p><strong>Return Date:</strong></p>
                        </div>
                        <div className="sub-data">
                          <div className="date-range">
                            {job?.paused_return_date ? (
                              job.paused_return_date
                            ) : 'Not Specified'}
                          </div>
                        </div>
                      </React.Fragment>
                    )
                    : ''}
                </div>
              ) : ''}
            </div>
            <div className="open-job-action-buttons">
              {job?.has_customer_notes ? (
                <div className="ask-question">
                  <Button
                    className={`job-notes ${job?.has_unread_customer_notes ? 'notification-dot' : ''}`}
                    onClick={() => setCustomerNotesModalData({ data: job })}
                  >
                    View Notes
                  </Button>
                </div>
              ) : ''}

              <div className="ask-question">
                <button type="button" onClick={() => setShowAskQuestionModal((prev) => ({ ...prev, status: true, data: job }))}>
                  Ask a Question
                </button>
              </div>

            </div>
            {/* {
              job.availability_date && job.availability_date.length > 0 && (
                <div className="jobs-card-bottom-section">
                  {
                    job.availability_date
                    && job.availability_date.map((availabilityDate) => (
                      <div className="data-row-bottom" key={generateRandomString()}>
                        <AppointmentIcon />
                        <p>
                          {availabilityDate}
                        </p>
                      </div>
                    ))
                  }
                </div>
              )
            } */}
            {/* {(job.job_schedule_type === 'normal'
              || job.job_schedule_type === 'emergency'
              || job.job_schedule_type === 'high')
              && (type === 'submitted' || type === 'pending') ? (
                <div className={`jobs-card-bottom-section priority-${job.job_schedule_type}`}>
                  <div className="data-row-bottom ">
                    <p>
                      Priority:
                      {' '}
                      {capitalize(job.job_schedule_type)}
                      {job.priority_attend_end_time && ' - '}
                      {job.priority_attend_end_time && renderTimer(job.priority_attend_end_time)}
                    </p>
                  </div>
                </div>
              ) : ''} */}
          </div>
        )
      }
      {showAskQuestionModal?.status ? (
        <AskQuestionModal
          data={showAskQuestionModal?.data}
          closeModal={() => setShowAskQuestionModal(false)}
        />
      ) : (
        ''
      )}
      {showPausedNotes ? (
        <PausedNotesModal
          showPausedNotes={showPausedNotes}
          toggle={() => setPausedNotes(false)}
          job={job}
        />
      ) : ''}
      {showDescription ? (
        <JobDescriptionModal
          showDescription={showDescription}
          toggle={() => setDescription(false)}
          jobId={job?.job_id}
          jobDescription={job?.description}
        />
      ) : ''}
      {!isEmpty(customerNotesModalData)
        ? (
          <CustomerNotesModal
            toggle={() => setCustomerNotesModalData({})}
            hideNotificationDot={() => {
              if (job?.has_unread_customer_notes) {
                onJobNotesModalCloseHandler(type, job?.job_id);
              }
            }}
            jobId={job?.job_id}
          />
        ) : ''}
    </React.Fragment>
  );
};

export default JobCard;
